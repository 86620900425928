import { useActions, useApiActions } from "state/hooks/index";
import { submitOneCallback, submitOneValidate } from "state/actions/api";
import { rejectRequest as reject } from "state/actions/data";
import { useSelector } from "react-redux";
import { selectIsDraft } from "state/selectors/data";
import { isValidForm } from "state/selectors/validation";
import { useCallback, useMemo } from "react";

export function useForm(type, id, cb, validate) {
    const { onSubmit } = useApiActions({
        onSubmit: validate ? submitOneValidate : submitOneCallback,
    });
    const { onReject } = useActions({ onReject: reject });

    const draft = useSelector((store) => selectIsDraft(store, type, id));
    const isValid = useSelector((store) => isValidForm(store, type, id));
    const formSubmit = useCallback(() => {
        const formCb = cb && typeof cb === "function" ? () => cb(draft) : null;
        onSubmit(formCb, type, id, null, null, null, {});
    }, [onSubmit, draft, type, id, cb]);

    const formReject = useCallback(() => {
        onReject(type, id);
        if (cb) cb(false);
    }, [onReject, type, id, cb]);

    return useMemo(
        () => ({
            onSubmit: formSubmit,
            onReject: formReject,
            isDraft: draft,
            isValid,
        }),
        [formSubmit, formReject, draft, isValid]
    );
}
