import { change } from "state/actions/data";
import { useCallback, useMemo } from "react";
import { useData, useActions } from "state/hooks";
import { getIn } from "lodash-redux-immutability";

export function useField(type, id, field) {
    const value = useData({ type, id });
    const { onChange } = useActions({ onChange: change });
    const handleChange = useCallback(
        (value, ...rest) => {
            let next = {};
            next[field] = value;
            onChange(type, id, next, ...rest);
        },
        [type, id, field, onChange]
    );
    const fieldValue = getIn(value, field); //value[field];

    return useMemo(
        () => ({
            value: fieldValue,
            onChange: handleChange,
        }),
        [fieldValue, handleChange]
    );
}
